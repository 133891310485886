<template>
  <div id="home">
    <!-- Banner Section Start -->
    <div class="rs-banner main-home pt-100 pb-100 md-pt-80 md-pb-80">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-12 pr-140 md-mb-140 md-pr-15">
            <div class="content-wrap">
              <h1 class="it-title">We are Nedex</h1>
              <div class="description">
                <p class="desc">
                  A Malaysian Team, we provide top-notch web design, <br/> web & mobile application development and digital marketing. Wholesome as we are.
                </p>
              </div>
              <div class="rs-videos">
                <div class="animate-border main-home">
                  <a class="popup-border popup-videos" href="https://www.youtube.com/watch?v=f73bwhVGOKI">
                    <i class="fa fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-12 pl-70 md-pl-15">
            <div class="rs-contact">
              <div class="contact-wrap">
                <div class="content-part mb-25">
                  <h2 class="title mb-15">Ask us anything! It's free</h2>
                </div>
                <div id="form-messages"></div>
                <HomeContactForm></HomeContactForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner Section End -->

    <!-- About Section Start -->
    <div id="rs-about" class="rs-about style1 pt-130 pb-190 md-pt-80 md-pb-80">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-12 md-mb-50 hidden-md">
            <div class="rs-animation-shape">
              <div class="pattern">
                <img src="~@/assets/images/new/about-home-banner-1-test.jpg" alt="">
              </div>
              <div class="middle">
                <img class="dance" src="~@/assets/images/new/about-home-banner-2-test.jpg" alt="">
              </div>
              <div class="bottom-shape">
                <img class="dance2" src="~@/assets/images/new/test-banner.jpg" alt="">
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 pl-40 md-pl-15 lg-pt-200 sm-pt-0">
            <div class="sec-title mb-30">
              <div class="sub-text">Why Us</div>
              <h2 class="title pb-20">
                We are lean, modern solutions provider
              </h2>
              <div class="desc pr-90 md-pr-0">
                We are a passionate young team from Malaysia that strives to deliver quality services for everyone, no matter you're a startup or a MNC. <br/>
                Our proven solutions and track record has shown that we have the
                expertise to grow your business now and in the future.
              </div>
            </div>
            <div class="btn-part mt-55">
              <router-link :to="{name: 'About'}" class="readon learn-more">Learn More</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- About Section End -->

    <!-- Services Section Start -->
    <div class="rs-services main-home gray-color pt-120 pb-120 md-pt-70 md-pb-70">
      <div class="container">
        <div class="sec-title2 text-center mb-45">
          <span class="sub-text">Services</span>
          <h2 class="title">
            What We Offer
          </h2>
          <div class="heading-line"></div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12 mb-25">
            <div class="services-item">
              <div class="services-icon">
                <div class="image-part">
                  <img src="~@/assets/images/services/main-home/icons/1.png" alt="">
                </div>
              </div>
              <div class="services-content">
                <div class="services-text">
                  <h3 class="services-title"><a href="software-development.html">System Development Service</a></h3>
                </div>
                <div class="services-desc">
                  <p>
                    Forget about the off-shelf solutions, we provide you a custom solution to fits right into your ever-evolving business needs.
                  </p>
                </div>
                <div class="btn-part mt-4">
                  <router-link :to="{name: 'SystemDevelopment'}" class="readon learn-more">Learn More</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-25">
            <div class="services-item">
              <div class="services-icon">
                <div class="image-part">
                  <img src="~@/assets/images/services/main-home/icons/2.png" alt="">
                </div>
              </div>
              <div class="services-content">
                <div class="services-text">
                  <h3 class="services-title"><a href="web-development.html">Web Development</a></h3>
                </div>
                <div class="services-desc">
                  <p>
                    Tailor-made website design that represents your brand yet most importantly, it can sell!
                  </p>
                </div>
              </div>
              <div class="btn-part mt-4">
                <router-link :to="{name: 'WebDesign'}" class="readon learn-more">Learn More</router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-25">
            <div class="services-item">
              <div class="services-icon">
                <div class="image-part">
                  <img src="~@/assets/images/services/main-home/icons/3.png" alt="">
                </div>
              </div>
              <div class="services-content">
                <div class="services-text">
                  <h3 class="services-title"><a href="services/social-media-marketing">Digitalization of Business</a></h3>
                </div>
                <div class="services-desc">
                  <p>
                    We help you to build a strong presence for your business across different platforms to capture your consumer needs.
                  </p>
                </div>
              </div>
              <div class="btn-part mt-4">
                <router-link :to="{name: 'SocialMediaMarketing'}" class="readon learn-more">Learn More</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Services Section End -->

    <!-- Partner Start -->
    <div class="rs-partner style2 pt-50 pb-50">
      <div class="container">
        <div class="sec-title2 text-center mb-45">
          <span class="sub-text">Clients</span>
          <h2 class="title">
            Trusted By
          </h2>
          <div class="heading-line"></div>
        </div>
        <!--<div class="rs-carousel" data-loop="true" data-items="6" data-margin="50" data-autoplay="true" data-hoverpause="true" data-autoplay-timeout="5000" data-smart-speed="800" data-dots="false" data-nav="false" data-nav-speed="false" data-center-mode="false" data-mobile-device="2" data-mobile-device-nav="false" data-mobile-device-dots="false" data-ipad-device="4" data-ipad-device-nav="false" data-ipad-device-dots="false" data-ipad-device2="3" data-ipad-device-nav2="false" data-ipad-device-dots2="false" data-md-device="5" data-md-device-nav="false" data-md-device-dots="false">-->
          <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center partner-item">
            <a href="#" class="py-5 py-sm-2 px-3"><img src="img/clients/tefal.png"/></a>
            <a href="#" class="py-5 py-sm-2 px-3"><img src="img/clients/ijm.png"/></a>
            <a href="#" class="py-5 py-sm-2 px-3"><img src="img/clients/mahb.png"/></a>
            <a href="#" class="py-5 py-sm-2 px-3"><img src="img/clients/paab.png"/></a>
            <a href="#" class="py-5 py-sm-2 px-3"><img src="img/clients/bio-essence.png"/></a>
          </div>
          <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center partner-item">
            <a href="#" class="py-5 py-sm-2 px-1"><img src="img/clients/bio-life.png"/></a>
            <a href="#" class="py-5 py-sm-2 px-1"><img src="img/clients/good-virtues-co.png"/></a>
            <a href="#" class="py-5 py-sm-2 px-1"><img src="img/clients/pixy.png"/></a>
            <a href="#" class="py-5 py-sm-2 px-1"><img src="img/clients/zp.png"/></a>
            <a href="#" class="py-5 py-sm-2 px-1"><img src="img/clients/prodental.png"/></a>
          </div>
          <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center partner-item">
            <a href="#" class="py-5 py-sm-3 px-3"><img src="img/clients/capsphere.png"/></a>
            <a href="#" class="py-5 py-sm-3 px-3"><img src="img/clients/gatsby_logo.png"/></a>
            <a href="#" class="py-5 py-sm-3 px-4"><img src="img/clients/chi_fitness.png"/></a>
            <a href="#" class="py-5 py-sm-3 px-4"><img src="img/clients/dreamax.png"/></a>
          </div>


      </div>
    </div>
    <!-- Partner End -->

    <!-- Project Section Start -->
    <div class="rs-project style6 modify1 pt-100 pb-100 md-pt-80 md-pb-80">
      <div class="container">
        <div class="sec-title2 text-center mb-45">
          <span class="sub-text">Project</span>
          <h2 class="title">
            Our Clients
          </h2>
          <p class="desc">Over the years, we're honoured to have worked with a wide range of clients from different industries.</p>
          <div class="heading-line"></div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/tefal.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://clubtefal.com.my/" target="_blank">Tefal Malaysia</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/gatsby.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://gatsby.com.my/" target="_blank">Gatsby</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 md-mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/faustino.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://faustino-msia.com/" target="_blank">Faustino</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/icommunity.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://icommunity.my/" target="_blank">iCOMMUNITY</a></h3>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-part mt-60 md-mt-30 text-center">
          <router-link :to="{name: 'Portfolio'}" class="readon learn-more">View More</router-link>
        </div>
      </div>
    </div>
    <!-- Project Section End -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeContactForm from "./partials/HomeContactForm";
export default {
  name: 'Home',
  components: {HomeContactForm},
  mounted() {
    $(document).ready(function () {
      // sticky menu
      var header = $('.menu-sticky');
      var win = $(window);

      win.on('scroll', function() {
        var scroll = win.scrollTop();
        if (scroll < 1) {
          header.removeClass("sticky");
        } else {
          header.addClass("sticky");
        }

        $("section").each(function() {
          var elementTop = $(this).offset().top - $('#rs-header').outerHeight();
          if(scroll >= elementTop) {
            $(this).addClass('loaded');
          }
        });

      });

      //window load
      $(window).on( 'load', function() {
        $("#loading").delay(1500).fadeOut(500);
        $("#loading-center").on( 'click', function() {
          $("#loading").fadeOut(500);
        })
      })

      // Parallax Stuff
      if ($("#stuff").length) {
        var stuff = $('#stuff').get(0);
        var parallaxInstance = new Parallax(stuff);
      }

      // onepage nav
      var navclose = $('#onepage-menu');
      if(navclose.length){
        $(".nav-menu li a").on("click", function () {
          if ($(".showhide").is(":visible")) {
            $(".showhide").trigger("click");
          }
        });

        if ($.fn.onePageNav) {
          $(".nav-menu").onePageNav({
            currentClass: "current-menu-item"
          });
        }
      }

      //Testimonials Slider
      var sliderfor = $('.slider-for');
      if(sliderfor.length){
        $('.slider-for').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: '.slider-nav',
          autoplay: true
        });
      }
      var slidernav = $('.slider-nav');
      if(slidernav.length){
        $('.slider-nav').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          asNavFor: '.slider-for',
          dots: false,
          centerMode: true,
          focusOnSelect: true,
        });
      }

      // collapse hidden
      var navMain = $(".navbar-collapse");
      navMain.on("click", "a:not([data-toggle])", null, function () {
        navMain.collapse('hide');
      });

      // video
      if ($('.player').length) {
        $(".player").YTPlayer();
      }

      // wow init
      new WOW().init();

      // image loaded portfolio init
      var gridfilter = $('.grid');
      if(gridfilter.length){
        $('.grid').imagesLoaded(function() {
          $('.gridFilter').on('click', 'button', function() {
            var filterValue = $(this).attr('data-filter');
            $grid.isotope({
              filter: filterValue
            });
          });
          var $grid = $('.grid').isotope({
            itemSelector: '.grid-item',
            percentPosition: true,
            masonry: {
              columnWidth: '.grid-item',
            }
          });
        });
      }

      // project Filter
      if ($('.gridFilter button').length) {
        var projectfiler = $('.gridFilter button');
        if(projectfiler.length){
          $('.gridFilter button').on('click', function(event) {
            $(this).siblings('.active').removeClass('active');
            $(this).addClass('active');
            event.preventDefault();
          });
        }
      }

      // magnificPopup init
      var imagepopup = $('.image-popup');
      if(imagepopup.length){
        $('.image-popup').magnificPopup({
          type: 'image',
          callbacks: {
            beforeOpen: function() {
              this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure animated zoomInDown');
            }
          },
          gallery: {
            enabled: true
          }
        });
      }

      // Get a quote popup
      var popupquote = $('.popup-quote');
      if(popupquote.length){
        $('.popup-quote').magnificPopup({
          type: 'inline',
          preloader: false,
          focus: '#qname',
          removalDelay: 500,
          callbacks: {
            beforeOpen: function() {
              this.st.mainClass = this.st.el.attr('data-effect');
              if(win.width() < 800) {
                this.st.focus = false;
              } else {
                this.st.focus = '#qname';
              }
            }
          }
        });
      }

      //preloader
      $(window).on('load', function() {
        $("#loader").delay(1000).fadeOut(500);
      })

      //Videos popup jQuery
      var popupvideos = $('.popup-videos');
      if(popupvideos.length){
        $('.popup-videos').magnificPopup({
          disableOn: 10,
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false
        });
      }

      //CountDown Timer
      var CountTimer = $('.CountDownTimer');
      if(CountTimer.length){
        $(".CountDownTimer").TimeCircles({
          fg_width: 0.030,
          bg_width: 0.8,
          circle_bg_color: "#eeeeee",
          circle_fg_color: "#eeeeee",
          time: {
            Days:{
              color: "#032390"
            },
            Hours:{
              color: "#032390"
            },
            Minutes:{
              color: "#032390"
            },
            Seconds:{
              color: "#032390"
            }
          }
        });
      }

      var pieChart = $('.progress-pie-part');
      if(pieChart.length) {
        $('.chart').easyPieChart({
          size: 172,
          barColor: "#032390",
          scaleLength: 0,
          lineWidth: 8,
          trackColor: "#eeeeee",
          lineCap: "circle",
          animate: 2000,
        });
      }

      /*-------------------------------------
          OwlCarousel
      -------------------------------------*/
/*      $('.rs-carousel').each(function() {
        var owlCarousel = $(this),
            loop = owlCarousel.data('loop'),
            items = owlCarousel.data('items'),
            margin = owlCarousel.data('margin'),
            stagePadding = owlCarousel.data('stage-padding'),
            autoplay = owlCarousel.data('autoplay'),
            autoplayTimeout = owlCarousel.data('autoplay-timeout'),
            smartSpeed = owlCarousel.data('smart-speed'),
            dots = owlCarousel.data('dots'),
            nav = owlCarousel.data('nav'),
            navSpeed = owlCarousel.data('nav-speed'),
            xsDevice = owlCarousel.data('mobile-device'),
            xsDeviceNav = owlCarousel.data('mobile-device-nav'),
            xsDeviceDots = owlCarousel.data('mobile-device-dots'),
            smDevice = owlCarousel.data('ipad-device'),
            smDeviceNav = owlCarousel.data('ipad-device-nav'),
            smDeviceDots = owlCarousel.data('ipad-device-dots'),
            smDevice2 = owlCarousel.data('ipad-device2'),
            smDeviceNav2 = owlCarousel.data('ipad-device-nav2'),
            smDeviceDots2 = owlCarousel.data('ipad-device-dots2'),
            mdDevice = owlCarousel.data('md-device'),
            centerMode = owlCarousel.data('center-mode'),
            HoverPause = owlCarousel.data('hoverpause'),
            mdDeviceNav = owlCarousel.data('md-device-nav'),
            mdDeviceDots = owlCarousel.data('md-device-dots');
        owlCarousel.owlCarousel({
          loop: (loop ? true : false),
          items: (items ? items : 4),
          lazyLoad: true,
          center: (centerMode ? true : false),
          autoplayHoverPause: (HoverPause ? true : false),
          margin: (margin ? margin : 0),
          //stagePadding: (stagePadding ? stagePadding : 0),
          autoplay: (autoplay ? true : false),
          autoplayTimeout: (autoplayTimeout ? autoplayTimeout : 1000),
          smartSpeed: (smartSpeed ? smartSpeed : 250),
          dots: (dots ? true : false),
          nav: (nav ? true : false),
          navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
          navSpeed: (navSpeed ? true : false),
          responsiveClass: true,
          responsive: {
            0: {
              items: (xsDevice ? xsDevice : 1),
              nav: (xsDeviceNav ? true : false),
              dots: (xsDeviceDots ? true : false),
              center: false,
            },
            768: {
              items: (smDevice2 ? smDevice2 : 2),
              nav: (smDeviceNav2 ? true : false),
              dots: (smDeviceDots2 ? true : false),
              center: false,
            },
            992: {
              items: (smDevice ? smDevice : 3),
              nav: (smDeviceNav ? true : false),
              dots: (smDeviceDots ? true : false),
              center: false,
            },
            1200: {
              items: (mdDevice ? mdDevice : 4),
              nav: (mdDeviceNav ? true : false),
              dots: (mdDeviceDots ? true : false),
            }
          }
        });
      });*/

      // Skill bar
      var skillbar = $('.skillbar');
      if(skillbar.length) {
        $('.skillbar').skillBars({
          from: 0,
          speed: 4000,
          interval: 100,
          decimals: 0,
        });
      }

      // Counter Up
      var counter = $('.rs-count');
      if(counter.length) {
        $('.rs-count').counterUp({
          delay: 20,
          time: 1500
        });
      }

      // scrollTop init
      var totop = $('#scrollUp');
      win.on('scroll', function() {
        if (win.scrollTop() > 150) {
          totop.fadeIn();
        } else {
          totop.fadeOut();
        }
      });
      totop.on('click', function() {
        $("html,body").animate({
          scrollTop: 0
        }, 500)
      });

      //canvas menu
      var navexpander = $('#nav-expander');
      if(navexpander.length){
        $('#nav-expander, #nav-close, .offwrap').on('click',function(e){
          e.preventDefault();
          $('body').toggleClass('nav-expanded');
        });
      }

      // View Course
      $('.course-view-part .view-icons .view-grid').on('click',function(e){
        e.preventDefault();
        $('.rs-popular-courses').removeClass('list-view');
      });
      $('.course-view-part .view-icons .view-list').on('click',function(e){
        e.preventDefault();
        $('.rs-popular-courses').addClass('list-view');
      });


      /*----------------------------
        single-productjs active
        ------------------------------ */
      var singleproductimage = $('.single-product-image');
      if(singleproductimage.length){
        $('.single-product-image').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: '.single-product-nav'
        });
      }

      var singleproductnav = $('.single-product-nav');
      if(singleproductnav.length){
        $('.single-product-nav').slick({
          slidesToShow: 3,
          asNavFor: '.single-product-image',
          dots: false,
          focusOnSelect: true,
          centerMode:false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 591,
              settings: {
                slidesToShow: 2
              }
            }
          ]
        });
      }

      // categories btn
      $('.cat-menu-inner').hide();
      $('.cat-btn').on('click',function(){
        $('.cat-menu-inner').slideToggle();
      })

      var tilt = $('.js-tilt');
      if(tilt.length) {
        const tilt = $('.js-tilt').tilt();
      }
    });
  }
}
</script>

<template>
  <div id="contact">

    <contact-form class="pt-60 md-pt-40"></contact-form>
  </div>
</template>

<script>
  import ContactForm from "@/views/partials/ContactForm";

  export default {
    name: "Contact",
    metaInfo: {
      title: 'Contact Us'
    },
    components: {ContactForm}
  }
</script>

<style scoped>

</style>

<template>
  <div id="footer">
    <!-- Footer Start -->
    <footer id="rs-footer" class="rs-footer">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-12 col-sm-12 footer-widget">
              <div class="footer-logo mb-30">
                <router-link :to="{name: 'Home'}"><img src="~@/assets/images/logo/nedex-inverted.svg" alt=""></router-link>
              </div>
              <div class="textwidget pb-30"><p>Nedex Group Sdn Bhd (Nedex Solutions) is a lean, modern technological solutions provider. Nedex provides services to a wide range of customers, from startups, SMEs to large corporations and GLCs.</p>
              </div>
              <ul class="footer-social md-mb-30">
                <li>
                  <a target="_blank" href="https://www.facebook.com/nedexdotio"><span><i class="fa fa-facebook"></i></span></a>
                </li>
                <li>
                  <a target="_blank" href="https://www.instagram.com/nedex_solutions/"><span><i class="fa fa-instagram"></i></span></a>
                </li>

              </ul>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 pl-45 md-pl-15 md-mb-30">
              <h3 class="widget-title">IT Services</h3>
              <ul class="site-map">
                <li><router-link :to="{name: 'WebDesign'}">Web Design</router-link></li>
                <li><router-link :to="{name: 'SystemDevelopment'}">System Development</router-link></li>
                <li><router-link :to="{name: 'SocialMediaMarketing'}">Social Media Marketing</router-link></li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 md-mb-30">
              <h3 class="widget-title">Contact Info</h3>
              <ul class="address-widget">
                <li>
                  <i class="flaticon-location"></i>
                  <div class="desc">1-1, USJ21/5, 47630 Subang Jaya</div>
                </li>
                <li>
                  <i class="flaticon-call"></i>
                  <div class="desc">
                    <a href="tel:+60358860986">(+60)3 5886 0986</a>
                  </div>
                </li>
                <li>
                  <i class="fa fa-facebook"></i>
                  <div class="desc">
                    <a href="https://m.me/nedexdotio">Talk to us on Facebook</a>
                  </div>
                </li>
                <li>
                  <i class="flaticon-clock-1"></i>
                  <div class="desc">
                    Opening Hours - 10AM to 5PM
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12">
              <h3 class="widget-title">Newsletter</h3>
              <p class="widget-desc">Subscribe to get latest promotions and news from us.</p>
              <p>
                <input type="email" name="EMAIL" placeholder="Your email address" required="">
                <em class="paper-plane"><input type="submit" value="Sign up"></em>
                <i class="flaticon-send"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="container">
          <div class="row y-middle">
            <div class="col-lg-6 text-right md-mb-10 order-last">
              <ul class="copy-right-menu">
                <li><router-link :to="{'name': 'Home'}">Home</router-link></li>
                <li><router-link :to="{'name': 'About'}">About Us</router-link></li>
                <li><router-link :to="{name: 'Portfolio'}">Portfolio</router-link></li>
                <li>
                  <a href="https://blog.nedex.io/">Our Blog</a>
                  <!--<router-link :to="{name: 'Blog'}">Our Blog</router-link>-->
                </li>
                <li><router-link :to="{name: 'Contact'}">Contact Us</router-link></li>
              </ul>
            </div>
            <div class="col-lg-6">
              <div class="copyright">
                <p>&copy; {{ new Date().getFullYear() }} Nedex Group Sdn Bhd (1274853-V). All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer End -->
  </div>
</template>

<script>
export default {
  name: "MyFooter"
}
</script>

<style scoped>

</style>

<template>
  <div id="nav">
    <!--Full width header Start-->
    <div class="full-width-header">
      <!--Header Start-->
      <header id="rs-header" class="rs-header">
        <!-- Topbar Area Start -->
        <div class="topbar-area">
          <div class="container">
            <div class="row rs-vertical-middle">
              <div class="col-lg-3">
                <div class="logo-part">
                  <router-link :to="{name: 'Home'}">
                    <img src="~@/assets/images/logo/nedex-logo-2023.webp" alt="Nedex Logo">
                  </router-link>
                </div>
              </div>
              <div class="col-lg-9 text-right">
                <ul class="rs-contact-info">
                  <li class="contact-part">
                    <i class="flaticon-location"></i>
                    <span class="contact-info">
                        <span>Address</span>
                        1-1, USJ21/5, 47630 Subang Jaya
                    </span>
                  </li>
                  <li class="contact-part no-border">
                    <i class="fa fa-facebook"></i>
                    <span class="contact-info">
                      <span>Talk to us on Facebook</span>
                      <a href="https://m.me/nedexdotio">Nedex Solutions</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Topbar Area End -->

        <!-- Menu Start -->
        <div class="menu-area menu-sticky">
          <div class="container">
            <div class="logo-area">
              <router-link :to="{name: 'Home'}">
                <img src="~@/assets/images/logo/nedex-logo-2023.webp" class="sticky-logo" alt="Nedex Logo">
              </router-link>
            </div>
            <div class="rs-menu-area">
              <div class="main-menu">
                <div class="mobile-menu">
                  <router-link :to="{name: 'Home'}" class="mobile-logo">
                    <img src="~@/assets/images/logo/nedex-inverted.svg" width="40" alt="">
                  </router-link>
                  <a href="#" class="rs-menu-toggle rs-menu-toggle-close">
                    <i class="fa fa-bars"></i>
                  </a>
                </div>
                <nav class="rs-menu">
                    <ul class="nav-menu">
                      <li>
                        <router-link :to="{'name': 'Home'}">Home</router-link>
                      </li>
                      <li>
                        <router-link :to="{'name': 'About'}">About Us</router-link>
                      </li>
                      <li class="menu-item-has-children">
                        <a href="#">Services</a>
                        <ul class="sub-menu">
                          <li><router-link :to="{name: 'WebDesign'}">Web Design</router-link></li>
                          <li><router-link :to="{name: 'SystemDevelopment'}">System Development</router-link></li>
                          <li><router-link :to="{name: 'SocialMediaMarketing'}">Social Media Marketing</router-link></li>
                        </ul>
                      </li>
                      <li>
                        <router-link :to="{name: 'Portfolio'}">Portfolio</router-link>
                      </li>
                      <li>
                        <router-link :to="{name: 'Contact'}">Contact Us</router-link>
                      </li>
                    </ul>
                  <!-- //.nav-menu -->
                </nav>
              </div> <!-- //.main-menu -->
            </div>
            <div class="expand-btn-inner search-icon hidden-sticky hidden-md">
              <!--<ul>
                <li class="sidebarmenu-search">
                  <a class="hidden-xs rs-search" data-target=".search-modal" data-toggle="modal" href="#">
                    <i class="flaticon-search"></i>
                  </a>
                </li>
              </ul>-->
              <div class="toolbar-sl-share">
                <ul class="social">
                  <li><a target="_blank" href="https://www.facebook.com/nedexdotio"><i class="fa fa-facebook"></i></a></li>
                  <li><a target="_blank" href="https://www.instagram.com/nedex_solutions/"><i class="fa fa-instagram"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Menu End -->
      </header>
      <!--Header End-->
    </div>
    <!--Full width header End-->
  </div>
</template>

<script>
export default {
  name: "Navbar"
}
</script>

<style scoped>

</style>

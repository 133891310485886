<template>
  <div id="services">
    <!-- Banner Section Start -->
    <div class="rs-banner style5">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-8 my-auto">
            <div class="banner-content z-index-1">
              <h1 class="title">Reach your audiences, at an <span>affordable</span> cost.</h1>
              <p class="desc">
                We provide social media marketing services, Ads optimizations and reporting to ensure your business stays ahead of competition in this ever-changing market.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-img">
        <div class="images-part">
          <img src="~@/assets/images/new/social-marketing/social-marketing-banner-test-1.jpg" class="round-corner" alt="">
        </div>
      </div>
    </div>
    <!-- Banner Section End -->

    <!-- Services Section Start -->
    <div class="rs-services style6 pt-120 pb-120 md-pt-80 md-pb-80">
      <div class="container">
        <div class="sec-title2 text-center mb-45">
          <span class="sub-text gold-color">Our Offer</span>
          <h2 class="title title7">
            What we can do for you
          </h2>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="services-item">
              <div class="services-icon">
                <img src="~@/assets/images/services/style8/1.png" alt="">
              </div>
              <div class="services-content">
                <div class="services-title">
                  <h3 class="title"><a href="web-development.html">Marketing Strategy Consultation</a></h3>
                </div>
                <p class="services-txt">Outsource your marketing department to us, we will provide consultation on your business and marketing strategy to reach your target audience.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="services-item">
              <div class="services-icon">
                <img src="~@/assets/images/services/style8/2.png" alt="">
              </div>
              <div class="services-content">
                <div class="services-title">
                  <h3 class="title"><a href="web-development.html">Social Media Marketing</a></h3>
                </div>
                <p class="services-txt">We can run and optimize your Advertisements on Facebook or Instagram through strategic marketing planning and advanced targeting techniques.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="services-item">
              <div class="services-icon">
                <img src="~@/assets/images/services/style8/3.png" alt="">
              </div>
              <div class="services-content">
                <div class="services-title">
                  <h3 class="title"><a href="web-development.html">Social Page management</a></h3>
                </div>
                <p class="services-txt">We can help you in managing your social media pages, including Facebook, Instagram. We can provide high quality and engaging content posting at request.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Services Section End -->

    <!-- About Section Start -->
    <div class="rs-about style4 pb-100 md-pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="testing-img">
              <img src="~@/assets/images/new/social-marketing/social-media-marketing-banner2.jpg" alt="">
            </div>
          </div>
          <div class="col-lg-6 pl-60 md-pl-15 md-pt-60">
            <div class="about-content">
              <div class="sec-title2 mb-45">
                <span class="sub-text gold-color">Know More</span>
                <h2 class="title title7">
                  Why you need social media marketing
                </h2>
                <p>It has become the most influential and important platform to target your potential customer with more effective way.</p>
              </div>
              <ul class="rs-features-list">
                <li><i class="fa fa-check"></i><span>High customer satisfaction </span></li>
                <li><i class="fa fa-check"></i><span>Increase brand awareness</span></li>
                <li><i class="fa fa-check"></i><span>Enhanced seo rankings</span></li>
                <li><i class="fa fa-check"></i><span>Cost effective</span></li>
                <li><i class="fa fa-check"></i><span>Community engagement</span></li>
                <li><i class="fa fa-check"></i><span>Generate sales and lead</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- About Section End -->

    <!-- Pricing section start -->
    <div class="rs-pricing style2 gray-color pt-120 pb-150 md-pt-80 md-pb-80">
      <div class="container">
        <div class="sec-title2 text-center mb-45">
          <span class="sub-text gold-color">Pricing</span>
          <h2 class="title title7">
            Our Social Marketing Plan
          </h2>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="pricing-table new-style2">
              <div class="pricing-badge">
                Basic
              </div>
              <div class="pricing-icon">
                <img src="assets/images/pricing/main-home/icons/1.png" alt="">
              </div>
              <div class="pricing-table-price">
                <div class="pricing-table-bags">
                  <span class="pricing-currency">RM</span>
                  <span class="table-price-text">1,999</span>
                  <span class="table-period">/per month</span>
                </div>
              </div>
              <div class="pricing-table-body">
                <ul>
                  <li><i class="fa fa-check"></i><span>Monthly media COS up to MYR 2,249</span></li>
                  <li><i class="fa fa-check"></i><span>Facebook Like/Post Engagement</span></li>
                  <li><i class="fa fa-check"></i><span>Increase Website Traffic</span></li>
                  <li><i class="fa fa-check"></i><span>Brand Awareness</span></li>
                  <li><i class="fa fa-check"></i><span>Facebook Remarketing</span></li>
                </ul>
              </div>
              <div class="btn-part">
                <a class="readon buy-now pricing" href="/contact-us">Enquire Now</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 md-mb-30 md-mt-30">
            <div class="pricing-table primary-bg">
              <div class="pricing-badge white-color-bg">
                Deluxe
              </div>
              <div class="pricing-icon">
                <img src="assets/images/pricing/main-home/icons/2.png" alt="">
              </div>
              <div class="pricing-table-price">
                <div class="pricing-table-bags">
                  <span class="pricing-currency">RM</span>
                  <span class="table-price-text">2,999</span>
                  <span class="table-period">/per month</span>
                </div>
              </div>
              <div class="pricing-table-body">
                <ul>
                  <li><i class="fa fa-check"></i><span>All In Basic</span></li>
                  <li><i class="fa fa-check"></i><span>Facebook Lead Generation</span></li>
                  <li><i class="fa fa-check"></i><span>Video Views</span></li>
                  <li><i class="fa fa-check"></i><span>Store Visits</span></li>
                </ul>
              </div>
              <div class="btn-part">
                <a class="readon buy-now pricing" href="/contact-us">Enquire Now</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="pricing-table new-style2">
              <div class="pricing-badge">
                Ultimate
              </div>
              <div class="pricing-icon">
                <img src="assets/images/pricing/main-home/icons/3.png" alt="">
              </div>
              <div class="pricing-table-price">
                <div class="pricing-table-bags">
                  <span class="pricing-currency">RM</span>
                  <span class="table-price-text">7,399</span>
                </div>
              </div>
              <div class="pricing-table-body">
                <ul>
                  <div class="pricing-table-price">
                    <div class="pricing-table-bags">
                      <span class="pricing-currency">RM</span>
                      <span class="table-price-text">3,999</span>
                      <span class="table-period">/per month</span>
                    </div>
                  </div>
                  <div class="pricing-table-body">
                    <ul>
                      <li><i class="fa fa-check"></i><span>All In Deluxe</span></li>
                      <li><i class="fa fa-check"></i><span>Promote App Install</span></li>
                      <li><i class="fa fa-check"></i><span>Increase Conversion</span></li>
                      <li><i class="fa fa-check"></i><span>Product Catalog Sales</span></li>
                    </ul>
                  </div>
                </ul>
              </div>
              <div class="btn-part">
                <a class="readon buy-now pricing" href="/contact-us">Enquire Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Pricing section end -->

    <contact-form></contact-form>
  </div>
</template>

<script>
  import ContactForm from "@/views/partials/ContactForm";
  export default {
    name: "SocialMediaMarketing",
    components: {ContactForm},
    metaInfo: {
      title: 'Social Media Marketing'
    },
    mounted() {
      $(document).ready(function () {
        // sticky menu
        var header = $('.menu-sticky');
        var win = $(window);

        win.on('scroll', function() {
          var scroll = win.scrollTop();
          if (scroll < 1) {
            header.removeClass("sticky");
          } else {
            header.addClass("sticky");
          }

          $("section").each(function() {
            var elementTop = $(this).offset().top - $('#rs-header').outerHeight();
            if(scroll >= elementTop) {
              $(this).addClass('loaded');
            }
          });

        });

        //window load
        $(window).on( 'load', function() {
          $("#loading").delay(1500).fadeOut(500);
          $("#loading-center").on( 'click', function() {
            $("#loading").fadeOut(500);
          })
        })

        // Parallax Stuff
        if ($("#stuff").length) {
          var stuff = $('#stuff').get(0);
          var parallaxInstance = new Parallax(stuff);
        }

        // onepage nav
        var navclose = $('#onepage-menu');
        if(navclose.length){
          $(".nav-menu li a").on("click", function () {
            if ($(".showhide").is(":visible")) {
              $(".showhide").trigger("click");
            }
          });

          if ($.fn.onePageNav) {
            $(".nav-menu").onePageNav({
              currentClass: "current-menu-item"
            });
          }
        }

        //Testimonials Slider
        var sliderfor = $('.slider-for');
        if(sliderfor.length){
          $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-nav',
            autoplay: true
          });
        }
        var slidernav = $('.slider-nav');
        if(slidernav.length){
          $('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: false,
            centerMode: true,
            focusOnSelect: true,
          });
        }

        // collapse hidden
        var navMain = $(".navbar-collapse");
        navMain.on("click", "a:not([data-toggle])", null, function () {
          navMain.collapse('hide');
        });

        // video
        if ($('.player').length) {
          $(".player").YTPlayer();
        }

        // wow init
        new WOW().init();

        // image loaded portfolio init
        var gridfilter = $('.grid');
        if(gridfilter.length){
          $('.grid').imagesLoaded(function() {
            $('.gridFilter').on('click', 'button', function() {
              var filterValue = $(this).attr('data-filter');
              $grid.isotope({
                filter: filterValue
              });
            });
            var $grid = $('.grid').isotope({
              itemSelector: '.grid-item',
              percentPosition: true,
              masonry: {
                columnWidth: '.grid-item',
              }
            });
          });
        }

        // project Filter
        if ($('.gridFilter button').length) {
          var projectfiler = $('.gridFilter button');
          if(projectfiler.length){
            $('.gridFilter button').on('click', function(event) {
              $(this).siblings('.active').removeClass('active');
              $(this).addClass('active');
              event.preventDefault();
            });
          }
        }

        // magnificPopup init
        var imagepopup = $('.image-popup');
        if(imagepopup.length){
          $('.image-popup').magnificPopup({
            type: 'image',
            callbacks: {
              beforeOpen: function() {
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure animated zoomInDown');
              }
            },
            gallery: {
              enabled: true
            }
          });
        }

        // Get a quote popup
        var popupquote = $('.popup-quote');
        if(popupquote.length){
          $('.popup-quote').magnificPopup({
            type: 'inline',
            preloader: false,
            focus: '#qname',
            removalDelay: 500,
            callbacks: {
              beforeOpen: function() {
                this.st.mainClass = this.st.el.attr('data-effect');
                if(win.width() < 800) {
                  this.st.focus = false;
                } else {
                  this.st.focus = '#qname';
                }
              }
            }
          });
        }

        //preloader
        $(window).on('load', function() {
          $("#loader").delay(1000).fadeOut(500);
        })

        //Videos popup jQuery
        var popupvideos = $('.popup-videos');
        if(popupvideos.length){
          $('.popup-videos').magnificPopup({
            disableOn: 10,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
          });
        }

        //CountDown Timer
        var CountTimer = $('.CountDownTimer');
        if(CountTimer.length){
          $(".CountDownTimer").TimeCircles({
            fg_width: 0.030,
            bg_width: 0.8,
            circle_bg_color: "#eeeeee",
            circle_fg_color: "#eeeeee",
            time: {
              Days:{
                color: "#032390"
              },
              Hours:{
                color: "#032390"
              },
              Minutes:{
                color: "#032390"
              },
              Seconds:{
                color: "#032390"
              }
            }
          });
        }

        var pieChart = $('.progress-pie-part');
        if(pieChart.length) {
          $('.chart').easyPieChart({
            size: 172,
            barColor: "#032390",
            scaleLength: 0,
            lineWidth: 8,
            trackColor: "#eeeeee",
            lineCap: "circle",
            animate: 2000,
          });
        }

        /*-------------------------------------
            OwlCarousel
        -------------------------------------*/
        $('.rs-carousel').each(function() {
          var owlCarousel = $(this),
              loop = owlCarousel.data('loop'),
              items = owlCarousel.data('items'),
              margin = owlCarousel.data('margin'),
              stagePadding = owlCarousel.data('stage-padding'),
              autoplay = owlCarousel.data('autoplay'),
              autoplayTimeout = owlCarousel.data('autoplay-timeout'),
              smartSpeed = owlCarousel.data('smart-speed'),
              dots = owlCarousel.data('dots'),
              nav = owlCarousel.data('nav'),
              navSpeed = owlCarousel.data('nav-speed'),
              xsDevice = owlCarousel.data('mobile-device'),
              xsDeviceNav = owlCarousel.data('mobile-device-nav'),
              xsDeviceDots = owlCarousel.data('mobile-device-dots'),
              smDevice = owlCarousel.data('ipad-device'),
              smDeviceNav = owlCarousel.data('ipad-device-nav'),
              smDeviceDots = owlCarousel.data('ipad-device-dots'),
              smDevice2 = owlCarousel.data('ipad-device2'),
              smDeviceNav2 = owlCarousel.data('ipad-device-nav2'),
              smDeviceDots2 = owlCarousel.data('ipad-device-dots2'),
              mdDevice = owlCarousel.data('md-device'),
              centerMode = owlCarousel.data('center-mode'),
              HoverPause = owlCarousel.data('hoverpause'),
              mdDeviceNav = owlCarousel.data('md-device-nav'),
              mdDeviceDots = owlCarousel.data('md-device-dots');
          owlCarousel.owlCarousel({
            loop: (loop ? true : false),
            items: (items ? items : 4),
            lazyLoad: true,
            center: (centerMode ? true : false),
            autoplayHoverPause: (HoverPause ? true : false),
            margin: (margin ? margin : 0),
            //stagePadding: (stagePadding ? stagePadding : 0),
            autoplay: (autoplay ? true : false),
            autoplayTimeout: (autoplayTimeout ? autoplayTimeout : 1000),
            smartSpeed: (smartSpeed ? smartSpeed : 250),
            dots: (dots ? true : false),
            nav: (nav ? true : false),
            navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
            navSpeed: (navSpeed ? true : false),
            responsiveClass: true,
            responsive: {
              0: {
                items: (xsDevice ? xsDevice : 1),
                nav: (xsDeviceNav ? true : false),
                dots: (xsDeviceDots ? true : false),
                center: false,
              },
              768: {
                items: (smDevice2 ? smDevice2 : 2),
                nav: (smDeviceNav2 ? true : false),
                dots: (smDeviceDots2 ? true : false),
                center: false,
              },
              992: {
                items: (smDevice ? smDevice : 3),
                nav: (smDeviceNav ? true : false),
                dots: (smDeviceDots ? true : false),
                center: false,
              },
              1200: {
                items: (mdDevice ? mdDevice : 4),
                nav: (mdDeviceNav ? true : false),
                dots: (mdDeviceDots ? true : false),
              }
            }
          });
        });

        // Skill bar
        var skillbar = $('.skillbar');
        if(skillbar.length) {
          $('.skillbar').skillBars({
            from: 0,
            speed: 4000,
            interval: 100,
            decimals: 0,
          });
        }

        // Counter Up
        var counter = $('.rs-count');
        if(counter.length) {
          $('.rs-count').counterUp({
            delay: 20,
            time: 1500
          });
        }

        // scrollTop init
        var totop = $('#scrollUp');
        win.on('scroll', function() {
          if (win.scrollTop() > 150) {
            totop.fadeIn();
          } else {
            totop.fadeOut();
          }
        });
        totop.on('click', function() {
          $("html,body").animate({
            scrollTop: 0
          }, 500)
        });

        //canvas menu
        var navexpander = $('#nav-expander');
        if(navexpander.length){
          $('#nav-expander, #nav-close, .offwrap').on('click',function(e){
            e.preventDefault();
            $('body').toggleClass('nav-expanded');
          });
        }

        // View Course
        $('.course-view-part .view-icons .view-grid').on('click',function(e){
          e.preventDefault();
          $('.rs-popular-courses').removeClass('list-view');
        });
        $('.course-view-part .view-icons .view-list').on('click',function(e){
          e.preventDefault();
          $('.rs-popular-courses').addClass('list-view');
        });


        /*----------------------------
          single-productjs active
          ------------------------------ */
        var singleproductimage = $('.single-product-image');
        if(singleproductimage.length){
          $('.single-product-image').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.single-product-nav'
          });
        }

        var singleproductnav = $('.single-product-nav');
        if(singleproductnav.length){
          $('.single-product-nav').slick({
            slidesToShow: 3,
            asNavFor: '.single-product-image',
            dots: false,
            focusOnSelect: true,
            centerMode:false,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2
                }
              },
              {
                breakpoint: 591,
                settings: {
                  slidesToShow: 2
                }
              }
            ]
          });
        }

        // categories btn
        $('.cat-menu-inner').hide();
        $('.cat-btn').on('click',function(){
          $('.cat-menu-inner').slideToggle();
        })

        var tilt = $('.js-tilt');
        if(tilt.length) {
          const tilt = $('.js-tilt').tilt();
        }
      });
    }
  }
</script>

<style scoped>

</style>
